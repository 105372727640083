<template>
  <div class="lead-item-border">
    <div class="lead-item-theme" @click="handleClick">
      <div class="lead-item-sources">
        <img v-lazy="item.headImg?.[0]" alt="">
      </div>
      <h3 class="lead-item-title">
        {{ item.title }}
      </h3>
      <div class="lead-item-content">
        {{ item.channel }} |
        {{ $global.formatDate($global.dateStringToTimestamp(item.published),'MM-dd-yyyy hh:mm') }}
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/module/lead/theme_1.scss';

export default {
  props: {
    item: {
      require: true,
      type: Object,
      default () {
        return {}
      }
    },
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
